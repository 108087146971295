function
    BindSearchGridData(id, columns, url, search) {
 
    $('#' + id + "_NoRecordFound").hide();
    $('.' + id).show();
    //ShowLoadingPannel(id)
    $('#' + id).dataTable({
        "bProcessing": true,
        "serverSide": true,
        "sort": true,
        "columns": columns,
        "bDestroy": true,
        "columnDefs": [{
            "targets": 'no-sort',
            "orderable": false,
        }],
        "ajax": {
            url: url, // json datasource
            data: search,
            type: "post",  // type of method  , by default would be get
            error: function (e) {  // error handling code
                setTimeout(function () {
                    $("#divLoad").remove();
                    $('#' + id + "_NoRecordFound").show();
                    $('.' + id).hide();
                }, 500);

            }
        },
        "initComplete": function (settings, data) {
            //HideLoadingPannel();
        }
    })
}

